var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(34, 34, 34, 0.27)","overlay-opacity":"0.8","max-width":"400","persistent":""},on:{"click:outside":_vm.close},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('v-card',[(_vm.dialogLoading)?_c('SkeletonPreloader',{attrs:{"count":1,"type":'list-item-two-line',"width":'100%',"height":'13vh'}}):[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onCreditSharing)}}},[_c('v-card-title',{staticClass:"black--text ft-light text-md"},[_vm._v("Share Credit To Sub Accounts "),_c('v-btn',{staticClass:"add-payment close-icon",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.close($event)}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("close")])])],1),_c('v-card-text',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 mb-2",attrs:{"tag":"div"}},[_c('v-autocomplete',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"solo":"","search-input":_vm.search,"dense":"","items":_vm.subAccounts,"item-value":"id","item-text":"name","color":"primary","clearable":"","hide-details":"","return-object":"","background-color":"grey lighten-4","append-icon":"search","flat":"","placeholder":"Search by name, email or phone ..."},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',{staticClass:"ft font-weight-medium"},[_vm._v(" Search through all "),_c('strong',[_vm._v("sub accounts")])])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[(_vm.shareCreditList.length > 0)?_c('span',{staticClass:"ft text-center font-weight-bold text-sm ml-1"},[_vm._v(" Local Acc. "+_vm._s(_vm.creditBalance.credit - _vm.localTotalAmountToBeShared)+" ")]):_vm._e(),(_vm.shareCreditList.length > 0)?_c('span',{staticClass:"ft text-center font-weight-bold text-sm ml-3"},[_vm._v(" International. Acc. "+_vm._s(_vm.creditBalance.intlCredit - _vm.intlTotalAmountToBeShared)+" ")]):_vm._e()]),(_vm.shareCreditList.length > 0)?_vm._l((_vm.shareCreditList),function(subAccount,index){return _c('div',{key:index,staticClass:"d-flex flex-row flex-grow-1 mt-1 px-1 rounded-md pt-4 grey lighten-4",staticStyle:{"border-radius":"4px"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 mx-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 ft font-weight-medium font-size-sm",attrs:{"dense":"","label":"Account Name","outlined":"","hide-details":"","readonly":"","error-messages":errors[0]},model:{value:(subAccount.name),callback:function ($$v) {_vm.$set(subAccount, "name", $$v)},expression:"subAccount.name"}})]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div","rules":subAccount.localTotalCredit !== null
                          ? 'required|numeric'
                          : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 ft font-weight-medium font-size-sm",attrs:{"dense":"","outlined":"","label":"Local Account","error-messages":errors[0]},model:{value:(subAccount.localTotalCredit),callback:function ($$v) {_vm.$set(subAccount, "localTotalCredit", $$v)},expression:"subAccount.localTotalCredit"}})]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-column",attrs:{"tag":"div","rules":subAccount.intlTotalCredit !== null
                          ? 'required|numeric'
                          : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-1 ft font-weight-medium font-size-sm",attrs:{"dense":"","outlined":"","label":"International Account","error-messages":errors[0]},model:{value:(subAccount.intlTotalCredit),callback:function ($$v) {_vm.$set(subAccount, "intlTotalCredit", $$v)},expression:"subAccount.intlTotalCredit"}})]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row pb-2 justify-end mt-n2"},[_c('v-btn',{staticClass:"mt-1",attrs:{"text":"","color":"red","small":""},on:{"click":function($event){return _vm.shareCreditList.splice(index, 1)}}},[_c('span',{staticClass:"ft text-capitalize font-weight-medium text-sm"},[_vm._v("Remove")])])],1)],1)])}):[_c('div',{staticClass:"d-flex flex-column flex-grow-1",staticStyle:{"align-items":"center"}},[_c('img',{staticClass:"mb-2",staticStyle:{"width":"80px"},attrs:{"src":require("@/assets/images/video-conference.png"),"alt":""}}),_c('span',{staticClass:"ft text-center font-weight-medium font-size-sm"},[_vm._v(" Click on a "),_c('kbd',[_vm._v("search")]),_vm._v(" results to add sub accounts ")]),_c('span',{staticClass:"ft text-center font-weight-bold pt-2 text-sm"},[_vm._v(" Local Acc. - "+_vm._s(_vm.creditBalance.credit)+" ")]),_c('span',{staticClass:"ft text-center font-weight-bold text-sm"},[_vm._v(" International. Acc. - "+_vm._s(_vm.creditBalance.intlCredit)+" ")])])]],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-sm ft text-capitalize",attrs:{"text":"","color":"accent"},on:{"click":_vm.close}},[_vm._v("Cancel ")]),_c('v-btn',{staticClass:"ft font-weight-medium text-sm text-capitalize",attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.loading ||
                _vm.shareCreditList.length === 0 ||
                _vm.creditBalance.credit - _vm.localTotalAmountToBeShared < 0 ||
                _vm.creditBalance.intlCredit - _vm.intlTotalAmountToBeShared < 0 ||
                _vm.shareCreditList.every(
                  function (item) { return item.localTotalCredit === null &&
                    item.intlTotalCredit === null; }
                ),"color":"primary"}},[_vm._v("Save")])],1)],1)]}}])})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }