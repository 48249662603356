
































































































































































































































import WidgetMixins from '../../../../mixins/WidgetMixins';
import Component, { mixins } from 'vue-class-component';
import { loadWidget } from '@/utils/helpers';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import { IShareCredit, IUser, PayloadState } from '@/types/types';
const usersModule = namespace('users');
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { email, numeric, required } from 'vee-validate/dist/rules';
import { debounce } from 'lodash';

extend('required', {
  ...required,
  message: 'Field is required',
});
extend('email', {
  ...email,
  message: 'Field must be an email address',
});
extend('numeric', {
  ...numeric,
  message: 'Field must contain only numbers',
});

@Component({
  name: 'ShareCreditDialog',
  components: {
    ValidationProvider,
    ValidationObserver,
    SkeletonPreloader: loadWidget('widgets/SkeletonPreloader'),
  },
})
export default class GetLink extends mixins(WidgetMixins) {
  @Getter('getResetFormState') resetFormState!: boolean;
  @Getter('getCreditBalance') creditBalance!: number;
  @usersModule.Getter('getResetFormValues') resetFormValues!: IUser;
  @usersModule.Getter('getSearchResults') subAccounts!: ReadonlyArray<IUser>;

  @Prop({ default: false, required: true }) state!: boolean;
  @Prop({ default: false }) dialogLoading!: boolean;
  @Prop({ default: false }) loading!: boolean; // button loading
  @Prop({ default: false }) link!: string;
  @Prop({ default: false }) useIntlRoute!: boolean;

  shareCreditList: Array<IShareCredit> = [];
  search: string | null = null;
  query: string | null = null;

  localTotalAmountToBeShared = 0;
  intlTotalAmountToBeShared = 0;

  @Watch('resetFormState')
  onFormChange(payload: boolean): void {
    if (payload) {
      this.shareCreditList = [];
    } else this.$store.dispatch('resetFormValues', false, { root: true });
  }

  searchText(term: string): void {
    if (term)
      this.$store.dispatch('users/list', {
        query: `?searchTerm=${term}&rawList=true`,
        rawList: true,
      });
  }

  handleOnSubAccountTextSearch = debounce(this.searchText, 500);

  @Watch('search')
  onSearchTermChange(term: string): void {
    this.handleOnSubAccountTextSearch(term);
  }

  @Watch('query')
  onQueryChange(value: IUser): void {
    if (value) {
      this.shareCreditList = [
        ...this.shareCreditList.filter((acc) => acc.id !== value.id),
        {
          id: value.id as string,
          name: value.name,
          localTotalCredit: null,
          intlTotalCredit: null,
        },
      ];
      this.search = '';
      this.query = '';
    }
  }

  @Watch('shareCreditList', {
    deep: true,
  })
  onShareCreditAmountChange(items: Array<IShareCredit>): void {
    this.localTotalAmountToBeShared = items.reduce(
      (acc, cur) => (acc += Number(cur.localTotalCredit)),
      0
    );
    this.intlTotalAmountToBeShared = items.reduce(
      (acc, cur) => (acc += Number(cur.intlTotalCredit)),
      0
    );
  }

  @Emit('shareCredit')
  onCreditSharing(): {
    credits: Array<IShareCredit>;
    localTotalCreditAmount: number;
    intlTotalCreditAmount: number;
  } {
    return {
      credits: this.shareCreditList,
      localTotalCreditAmount: this.localTotalAmountToBeShared,
      intlTotalCreditAmount: this.intlTotalAmountToBeShared,
    };
  }

  @Emit('actions')
  close(): PayloadState {
    return {
      idx: 'details',
      state: false,
    };
  }
}
